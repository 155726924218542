
  import { getPermisionPlanning } from "@/utils/permissionResolve";
  import MarketplaceDialog from "@/views/Admin/Persons/V10/Dependencies/Dialogs/BetaMarketplace/index.vue";
  import Vue from "vue";
  import { mapGetters } from "vuex";

  import SyncPostMessageMixin from "@/mixins/SyncPostMessage";

  export default Vue.extend({
    name: "IFrame",
    mixins: [SyncPostMessageMixin],
    props: {
      src: {
        type: String,
        required: true,
      },
      width: {
        type: String,
        default: "100%",
      },
      height: {
        type: String,
        default: "1200px",
      },
      iframe_id: {
        type: String,
        default: Math.ceil(Date.now() / 1000).toString(),
      },
    },
    components: { MarketplaceDialog },
    data: () => ({
      loaded: false,
    }),
    created() { },
    mounted() { },
    computed: {
      ...mapGetters("loading", ["isLoading"]),
      ...mapGetters("internationalization", ["getLanguage"]),
      ...mapGetters("auth", ["getToken"]),
      ...mapGetters("profile", ["getAbility"]),
    },
    methods: {
      handlerError(event: any) {
        this.loaded = true;
      },

      async handlerLoad(event: any) {
        if (event.type !== "load") return;
        this.loaded = true;
        await this.dispatchMessage({ refName: this.iframe_id, locale: this.getLanguage });
      },

      getPermission() {
        return getPermisionPlanning();
      },

      canViewIframe() {
        //TODO - For now redirect
        if (!this.getAbility.can(this.getPermission.actions.planning_2.index, this.getPermission.subject)) {
          this.$router.push("/");
        }
      },
    },
    watch: {
      getToken(val) {
        this.loaded = true;
        this.dispatchMessage({ refName: this.iframe_id, locale: this.getLanguage });
      },
      getLanguage(val) {
        this.loaded = true;
        this.dispatchMessage({ refName: this.iframe_id, locale: this.getLanguage });
      },
      src(val) {
        this.loaded = false;
      },
    },
  });
