import Vue from "vue";
import Header from "@/views/Admin/Commons/Header.vue";
import CreateTabs from "@/views/Admin/Commons/Tabs.vue";
import Alertize from "@/components/Alertize.vue";
import { mapActions, mapGetters } from "vuex";
import IFrame from "@/views/Admin/AudienceBlender/Report/Iframe.vue";
import { getPermisionMenu } from "@/utils/permissionResolve";
import { MARKET_PLACE_REF } from "@/models/persons/v10/DataDefault";

export default Vue.extend({
  name: "audienceMarketplace",
  props: {},
  components: { Header, CreateTabs, Alertize, IFrame },
  data: () => ({
    title: "audienceMarketplaceLogic",
    currentTab: 0,
    expanded: false,
    src: process.env.VUE_APP_FRONT_PERSONS_URL || "",
  }),
  async created() {
    this.$nextTick(async () => {
      this.src = process.env.VUE_APP_FRONT_PERSONS_URL || "";
    });
  },
  async mounted() {},
  computed: {
    ...mapGetters("loading", ["isLoading"]),
    ...mapGetters("profile", ["getPermission", "getAbility"]),
    getPermissionData() {
      return getPermisionMenu();
    },
    canAccess() {
      return this.getAbility.can(this.getPermissionData.actions.audienceMarketplace, this.getPermissionData.subject);
    },
  },
  methods: {
    ...mapActions("loading", ["setLoadingData"]),
    getRefIFrame() {
      return MARKET_PLACE_REF;
    },
  },
  watch: {
    getPermission: {
      async handler(val, oldVal) {
        await this.setLoadingData();
      },
      deep: true,
      immediate: true,
    },
  },
});
