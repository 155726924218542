import Vue from "vue";
import DialogDefault from "@/components/Content/Dialogs/Default.vue";
import CardActions from "@/components/Content/CardActions.vue";

export default Vue.extend({
  name: "MarketplaceDialog",

  components: {
    DialogDefault,
    CardActions,
  },

  data: () => ({
    show: true,
  }),

  methods: {
    handleAction(data: { type: "close" }) {
      if (data.type === "close") {
        this.closeModal();
      }
    },

    closeModal() {
      this.show = false;
    },
  },
});
