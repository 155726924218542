import { getTokenFromStorage } from "@/services/storage-service";
import store from "@/store";
import { sleep } from "@/utils/convert";
import { Component, Vue } from "vue-property-decorator";

export interface MessagePayload {
  locale: string;
  token: string;
}

@Component
export default class SyncPostMessageMixin extends Vue {
  /**
   * Envía un mensaje a un iframe especificado con datos de localización y token.
   * @param refName - Nombre del ref del iframe.
   */
  public async dispatchMessage(payload: { refName: string; locale: string }): Promise<void> {
    try {
      const iframe = this.getIframeByRef(payload.refName);
      
      await sleep(1000);

      if (iframe) {
        const message: MessagePayload = this.createMessagePayload(payload.locale);
        this.sendMessageToIframe(iframe, message);
      } else {
        console.warn(`El iframe con el ref "${payload.refName}" no está disponible o no tiene contentWindow.`);
      }
    } catch (error) {
      console.error("Error al enviar el mensaje:", error);
    }
  }

  /**
   * Obtiene el iframe según el nombre del ref.
   * @param refName - Nombre del ref del iframe.
   * @returns El elemento iframe o null si no existe.
   */
  private getIframeByRef(refName: string): HTMLIFrameElement | null {
    const iframe = this.$refs[refName] as HTMLIFrameElement;
    return iframe && iframe.contentWindow ? iframe : null;
  }

  /**
   * Crea el payload del mensaje.
   * @returns El payload del mensaje con locale y token.
   */
  private createMessagePayload(locale: string): MessagePayload {
    return {
      locale,
      token: this.getTokenFromStore(),
    };
  }

  /**
   * Envía el mensaje al iframe usando postMessage.
   * @param iframe - El elemento iframe al que se envía el mensaje.
   * @param message - El mensaje que se enviará.
   */
  private sendMessageToIframe(iframe: HTMLIFrameElement, message: MessagePayload): void {
    iframe.contentWindow?.postMessage(message, "*");
  }

  private getTokenFromStore(): string {
    return store.getters["auth/getToken"];
  }
}
